import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService, UserRole } from '@ess-front/shared';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  private readonly MEMBERS_AREA_URL = '/members-area';
  private readonly STAFF_AREA_URL = '/staff-area';

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      const role = this.authService.getAuthData()?.role;
      if (role) {
        const redirectUrl = role === UserRole.STAFF ? this.STAFF_AREA_URL : this.MEMBERS_AREA_URL;
        this.router.navigateByUrl(redirectUrl);
      }
      return false;
    } else {
      return true;
    }
  }
}
