import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environment';
import { WINDOW } from '@ess-front/shared';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  private readonly PROPOSAL_URL_REGEX = /^\/proposal\/[^/]+\/destination\/[^/]+$/;

  constructor(@Inject(WINDOW) private window: Window) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url.includes('apply')) {
      this.redirectToLandingPage();
      return false;
    }

    if (this.PROPOSAL_URL_REGEX.test(state.url)) {
      const hash = route.paramMap.get('hash');
      const destination = route.paramMap.get('destination');
      this.redirectToProposalDestination(hash, destination);
      return false;
    }

    return true;
  }

  private redirectToLandingPage(): void {
    this.window.location.href = environment.publicWebsite;
  }

  private redirectToProposalDestination(hash: string, destination: string): void {
    this.window.location.href = `${environment.proposalWebsite}${hash}/destination/${destination}`;
  }
}
