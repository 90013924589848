import { ErrorHandler, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { delay, Observable, of, retry, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@ess-front/shared';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private errorHandler: ErrorHandler,
    private router: Router,
    private authService: AuthService,
  ) {}

  intercept<T, K>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<K>> {
    return next.handle(request).pipe(
      // retries 2 times, 1 sec delay, for 404 errors
      retry({
        count: 2,
        delay: (error: unknown) => {
          return error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound
            ? of(true).pipe(delay(1000))
            : throwError(() => error);
        },
      }),
      catchError((error: unknown) => {
        this.errorHandler.handleError(error);
        if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatusCode.Unauthorized) {
            this.authService.clearAuthData();
            this.router.navigate(['login'], { state: { redirectUrl: this.router.url } });
          } else if (error.status === HttpStatusCode.Forbidden) {
            this.router.navigate(['/forbidden']);
          } else if (error.status === HttpStatusCode.NotFound) {
            this.router.navigate(['/not-found']);
          }
        }
        return throwError(() => error);
      }),
    );
  }
}
