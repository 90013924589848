import { AuthService } from '@ess-front/shared';
import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { UserDomainService } from '@app/domain/user-domain.service';
import { of, take } from 'rxjs';

function initializeUser(authService: AuthService, userDomainService: UserDomainService) {
  return () => {
    if (authService.isAuthenticated()) {
      userDomainService.getUser$().pipe(take(1)).subscribe();
    }
    return of(true);
  };
}

export const userInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeUser,
  deps: [AuthService, UserDomainService],
  multi: true,
};
